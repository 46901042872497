import React from "react";
import { BlobContainer } from "./styled";

const Blob = ({ isServicesAbout, isWork, isContact, isProject, isHome }) => {
  return (
    <BlobContainer
      isServicesAbout={isServicesAbout}
      isContact={isContact}
      isWork={isWork}
      isHome={isHome}
      isProject={isProject}
    >
      <div className="glowy-blob">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </BlobContainer>
  );
};

export default Blob;
