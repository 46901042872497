import styled from "styled-components";

import media from "../../../styles/media";

export const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 100px);
  width: 100vw;
  overflow: hidden;

  ${({ isHome }) =>
    isHome &&
    `
      transform: translate(-100px, -100px);
    `};

  ${media.tablet`
    ${({ isHome }) =>
      isHome &&
      `
       transform: translate(0px, -100px);
    `};
  `};

  ${media.thone`
    ${({ isHome }) =>
      isHome &&
      `
       transform: translate(0px, -100px);
    `};
  `};

  ${({ isServicesAbout }) =>
    isServicesAbout &&
    `
    transform: translate(200px, -210px);
  `};

  ${media.tablet`
    ${({ isServicesAbout }) =>
      isServicesAbout &&
      `
       transform: translate(100px, -210px);
    `};
  `};

  ${media.thone`
    ${({ isServicesAbout }) =>
      isServicesAbout &&
      `
       transform: translate(0px, -210px);
    `};
  `};

  ${({ isContact }) =>
    isContact &&
    `
    transform: translate(200px, -350px);
  `};

  ${media.tablet`
    ${({ isContact }) =>
      isContact &&
      `
      transform: translate(50px, -250px);
    `};
  `};

  ${media.thone`
    ${({ isContact }) =>
      isContact &&
      `
      transform: translate(0px, -250px);
    `};
  `};

  ${({ isWork }) =>
    isWork &&
    `
    transform: translate(0px, -350px);
  `};

  ${media.tablet`
    ${({ isWork }) =>
      isWork &&
      `
      transform: translate(50px, -350px);
    `};
  `};

  ${media.thone`
    ${({ isWork }) =>
      isWork &&
      `
      transform: translate(0px, -350px);
    `};
  `};

  ${({ isProject }) =>
    isProject &&
    `
    transform: translate(-350px, 0px);
  `};

  ${media.tablet`
    ${({ isProject }) =>
      isProject &&
      `
      transform: translate(-200px, -100px);
    `};
  `};

  ${media.thone`
    ${({ isProject }) =>
      isProject &&
      `
      transform: translate(0px, -100px);
    `};
  `};
  -webkit-filter: hue-rotate(0deg);
  filter: hue-rotate(0deg);
  -moz-animation: huerot 10s ease infinite;
  -webkit-animation: huerot 10s ease infinite;
  animation: huerot 10s ease infinite;

  .glowy-blob {
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50, -50);
    -ms-transform: translate(-50, -50);
    -webkit-transform: translate(-50, -50);
    transform: translate(-50, -50);
    -webkit-filter: url("#goo");
    filter: url("#goo");
  }
  .glowy-blob div {
    width: 120px;
    height: 170px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 220px;
    height: 270px;
    `};
    background: #85ede8;
    -moz-border-radius: 500px;
    -webkit-border-radius: 500px;
    border-radius: 500px;
    display: inline-block;
    position: absolute;
    box-shadow: 0px 0px 50px #85ede8;
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
    /**keyframes **/
  }
  .glowy-blob div:nth-child(2) {
    width: 65px;
    height: 65px;
    left: 11px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 145px;
    height: 145px;
    `};
  }
  .glowy-blob div:nth-child(3) {
    width: 107px;
    height: 107px;
    left: -76px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 175px;
    height: 175px;
    `};
  }
  .glowy-blob div:nth-child(4) {
    width: 67px;
    height: 67px;
    left: -52px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 147px;
    height: 147px;
    `};
  }
  .glowy-blob div:nth-child(5) {
    width: 79px;
    height: 79px;
    left: 44px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 169px;
    height: 169px;
    `};
  }
  .glowy-blob div:nth-child(6) {
    width: 45px;
    height: 45px;
    left: -60px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 125px;
    height: 125px;
    `};
  }
  .glowy-blob div:nth-child(7) {
    width: 52px;
    height: 52px;
    left: -49px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 132px;
    height: 132px;
    `};
  }
  .glowy-blob div:nth-child(8) {
    width: 101px;
    height: 101px;
    left: 18px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 181px;
    height: 181px;
    `};
  }
  .glowy-blob div:nth-child(9) {
    width: 64px;
    height: 64px;
    left: 3px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 144px;
    height: 144px;
    `};
  }
  .glowy-blob div:nth-child(10) {
    width: 41px;
    height: 41px;
    left: 65px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 121px;
    height: 121px;
    `};
  }
  .glowy-blob div:nth-child(11) {
    width: 69px;
    height: 69px;
    left: -13px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 149px;
    height: 149px;
    `};
  }
  .glowy-blob div:nth-child(12) {
    width: 75px;
    height: 75px;
    left: -52px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 165px;
    height: 165px;
    `};
  }
  .glowy-blob div:nth-child(13) {
    width: 78px;
    height: 78px;
    left: -44px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 168px;
    height: 168px;
    `};
  }
  .glowy-blob div:nth-child(14) {
    width: 81px;
    height: 81px;
    left: 29px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 161px;
    height: 161px;
    `};
  }
  .glowy-blob div:nth-child(15) {
    width: 71px;
    height: 71px;
    left: 34px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 151px;
    height: 151px;
    `};
  }
  .glowy-blob div:nth-child(16) {
    width: 57px;
    height: 57px;
    left: 3px;
    ${({ isHome }) =>
      isHome &&
      `
    width: 137px;
    height: 137px;
    `};
  }
  .glowy-blob div:nth-child(1) {
    top: 0;
    left: 0;
    -moz-animation: anim-1 10s ease-in-out infinite;
    -webkit-animation: anim-1 10s ease-in-out infinite;
    animation: anim-1 10s ease-in-out infinite;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  @-moz-keyframes anim-1 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 11px;
      top: 0;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-1 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 11px;
      top: 0;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-1 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 11px;
      top: 0;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(2) {
    top: 0;
    left: 0;
    -moz-animation: anim-2 10s ease-in-out infinite;
    -webkit-animation: anim-2 10s ease-in-out infinite;
    animation: anim-2 10s ease-in-out infinite;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  @-moz-keyframes anim-2 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -76px;
      top: 34px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-2 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -76px;
      top: 34px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-2 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -76px;
      top: 34px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(3) {
    top: 0;
    left: 0;
    -moz-animation: anim-3 10s ease-in-out infinite;
    -webkit-animation: anim-3 10s ease-in-out infinite;
    animation: anim-3 10s ease-in-out infinite;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  @-moz-keyframes anim-3 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -57px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-3 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -57px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-3 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -57px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(4) {
    top: 0;
    left: 0;
    -moz-animation: anim-4 10s ease-in-out infinite;
    -webkit-animation: anim-4 10s ease-in-out infinite;
    animation: anim-4 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-4 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 44px;
      top: 32px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-4 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 44px;
      top: 32px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-4 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 44px;
      top: 32px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(5) {
    top: 0;
    left: 0;
    -moz-animation: anim-5 10s ease-in-out infinite;
    -webkit-animation: anim-5 10s ease-in-out infinite;
    animation: anim-5 10s ease-in-out infinite;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  @-moz-keyframes anim-5 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -60px;
      top: -62px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-5 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -60px;
      top: -62px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-5 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -60px;
      top: -62px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(6) {
    top: 0;
    left: 0;
    -moz-animation: anim-6 10s ease-in-out infinite;
    -webkit-animation: anim-6 10s ease-in-out infinite;
    animation: anim-6 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-6 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -49px;
      top: -15px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-6 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -49px;
      top: -15px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-6 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -49px;
      top: -15px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(7) {
    top: 0;
    left: 0;
    -moz-animation: anim-7 10s ease-in-out infinite;
    -webkit-animation: anim-7 10s ease-in-out infinite;
    animation: anim-7 10s ease-in-out infinite;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  @-moz-keyframes anim-7 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 18px;
      top: -77px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-7 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 18px;
      top: -77px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-7 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 18px;
      top: -77px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(8) {
    top: 0;
    left: 0;
    -moz-animation: anim-8 10s ease-in-out infinite;
    -webkit-animation: anim-8 10s ease-in-out infinite;
    animation: anim-8 10s ease-in-out infinite;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  @-moz-keyframes anim-8 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: -44px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-8 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: -44px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-8 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: -44px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(9) {
    top: 0;
    left: 0;
    -moz-animation: anim-9 10s ease-in-out infinite;
    -webkit-animation: anim-9 10s ease-in-out infinite;
    animation: anim-9 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-9 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 65px;
      top: 16px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-9 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 65px;
      top: 16px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-9 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 65px;
      top: 16px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(10) {
    top: 0;
    left: 0;
    -moz-animation: anim-10 10s ease-in-out infinite;
    -webkit-animation: anim-10 10s ease-in-out infinite;
    animation: anim-10 10s ease-in-out infinite;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  @-moz-keyframes anim-10 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -13px;
      top: -35px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-10 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -13px;
      top: -35px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-10 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -13px;
      top: -35px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(11) {
    top: 0;
    left: 0;
    -moz-animation: anim-11 10s ease-in-out infinite;
    -webkit-animation: anim-11 10s ease-in-out infinite;
    animation: anim-11 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-11 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -28px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-11 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -28px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-11 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -52px;
      top: -28px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(12) {
    top: 0;
    left: 0;
    -moz-animation: anim-12 10s ease-in-out infinite;
    -webkit-animation: anim-12 10s ease-in-out infinite;
    animation: anim-12 10s ease-in-out infinite;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  @-moz-keyframes anim-12 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -44px;
      top: -69px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-12 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -44px;
      top: -69px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-12 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: -44px;
      top: -69px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(13) {
    top: 0;
    left: 0;
    -moz-animation: anim-13 10s ease-in-out infinite;
    -webkit-animation: anim-13 10s ease-in-out infinite;
    animation: anim-13 10s ease-in-out infinite;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  @-moz-keyframes anim-13 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 29px;
      top: -61px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-13 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 29px;
      top: -61px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-13 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 29px;
      top: -61px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(14) {
    top: 0;
    left: 0;
    -moz-animation: anim-14 10s ease-in-out infinite;
    -webkit-animation: anim-14 10s ease-in-out infinite;
    animation: anim-14 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-14 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 34px;
      top: -18px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-14 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 34px;
      top: -18px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-14 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 34px;
      top: -18px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:nth-child(15) {
    top: 0;
    left: 0;
    -moz-animation: anim-15 10s ease-in-out infinite;
    -webkit-animation: anim-15 10s ease-in-out infinite;
    animation: anim-15 10s ease-in-out infinite;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  @-moz-keyframes anim-15 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: 58px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes anim-15 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: 58px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes anim-15 {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 3px;
      top: 58px;
    }
    100% {
      left: 0;
      top: 0;
    }
  }
  .glowy-blob div:hover {
    cursor: default;
  }

  span {
    color: #85ede8;
    opacity: 0.5;
    font-size: 24px;
    font-family: "Chewy";
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    left: 50%;
    top: 30%;
    -moz-transform: translate(-50, -50);
    -ms-transform: translate(-50, -50);
    -webkit-transform: translate(-50, -50);
    transform: translate(-50, -50);
    -webkit-touch-callout: none;
    /* iOS Safari */
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  span:hover {
    cursor: default;
  }

  @-moz-keyframes huerot {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
  @-webkit-keyframes huerot {
    0% {
      -webkit-filter: hue-rotate(0deg);
      filter: hue-rotate(0deg);
    }
    100% {
      -webkit-filter: hue-rotate(360deg);
      filter: hue-rotate(360deg);
    }
  }
  @keyframes huerot {
    0% {
      -webkit-filter: hue-rotate(0deg);
      filter: hue-rotate(0deg);
    }
    100% {
      -webkit-filter: hue-rotate(360deg);
      filter: hue-rotate(360deg);
    }
  }
  @-moz-keyframes bounceback {
    100% {
      left: 0;
      top: 0;
    }
  }
  @-webkit-keyframes bounceback {
    100% {
      left: 0;
      top: 0;
    }
  }
  @keyframes bounceback {
    100% {
      left: 0;
      top: 0;
    }
  }
`;
