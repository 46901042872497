import React, { useRef } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";

import TransitionLink, {
  TransitionPortal,
} from "gatsby-plugin-transition-link";

const AnimationLink = (props) => {
  const { navLink, children, coverContentRef, pageLayoutRef } = props;
  const transitionNavCoverRef = useRef();
  const transitionWhiteCoverRef = useRef();

  const verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === "up" ? "-100%" : "100%";
    const directionFrom = direction === "up" ? "100%" : "-100%";
    const seconds = length;
    return gsap
      .timeline()
      .set(transitionWhiteCoverRef.current, { y: directionFrom })
      .to(transitionWhiteCoverRef.current, {
        y: "0%",
        ease: "power1.easeInOut",
        duration: seconds / 2,
      })
      .set(pageLayoutRef.current, { opacity: 0 })
      .to(transitionWhiteCoverRef.current, {
        y: directionTo,
        ease: "power1.easeIn",
        duration: seconds / 2,
      });
  };

  return (
    <>
      <TransitionLink
        to={navLink}
        exit={{
          length: 1.2,
          state: { layoutTheme: "dark" },
          trigger: ({ exit }) => verticalAnimation(exit, "up"),
        }}
        entry={{ delay: 0.5, length: 1, state: { layoutTheme: "dark" } }}
      >
        {children}
      </TransitionLink>
      <TransitionPortal>
        <div
          ref={transitionNavCoverRef}
          style={{
            position: "fixed",
            background: "#2c2c2c",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            transform: "translateY(100%)",
          }}
        />
        <div
          ref={transitionWhiteCoverRef}
          style={{
            position: "fixed",
            background: "#fb8fa4",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            transform: "translateY(100%)",
          }}
        />
      </TransitionPortal>
    </>
  );
};

AnimationLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnimationLink;
