import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/footer/footer";
import FooterDetails from "../components/footer/footer-details";
import FooterMapDetails from "../components/footer/footer-map-details";

// Module Services Fragment
export const moduleFooterFragment = graphql`
  fragment ModuleFooterFragment on Node {
    ... on ContentfulModuleFooter {
      __typename
      contentful_id
      name
      type
      menu {
        ...ItemListFragment
      }
      logo {
        title
        fluid(
          maxWidth: 1000
          quality: 95
          toFormat: PNG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      icon {
        title
        fluid(
          maxWidth: 100
          quality: 95
          toFormat: PNG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      backgroundImage {
        title
        fluid(
          maxWidth: 1400
          quality: 95
          toFormat: PNG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        description
      }
      copyright
    }
  }
`;

const ModuleFooter = (props) => {
  const { type } = props;
  switch (type) {
    case "map-details": {
      return <FooterMapDetails {...props} />;
    }

    case "details": {
      return <FooterDetails {...props} />;
    }

    case "simple": {
      return <Footer {...props} />;
    }

    default: {
      return null;
    }
  }
};

export default ModuleFooter;
