import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
// import favicon from "../images/favicon.ico";

const SEO = ({ data, location }) => {
  const {
    pageUrl,
    pageTitle,
    indexibility,
    socialSharingImage,
    metaDescription,
  } = data;
  const noIndexOption =
    indexibility === "Non-indexable"
      ? false
      : indexibility === "Indexable"
      ? true
      : null;
  const title = pageTitle;

  const description = metaDescription ? metaDescription.metaDescription : null;
  const ogTagImage = socialSharingImage
    ? `https:${socialSharingImage.file.url}`
    : null;

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: location?.origin + pageUrl,
      name: title,
      alternateName: "belo",
      structuredData: {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name: "belo",
      },
    },
  ];

  const robot = getRobot(noIndexOption);

  return (
    <Helmet>
      {/* <link rel="icon" href={favicon} /> */}
      {/* General tags */}
      <link rel="canonical" href={`${location?.origin}`} />
      <title>{title}</title>
      {/* <meta name="image" content={image} /> */}
      <meta name="description" content={description} />
      {robot}
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta
        property="og:image"
        content={
          ogTagImage
            ? ogTagImage
            : "https://images.ctfassets.net/q9zkntem569v/1qy3NkuH1G4f0CTA4PDsMC/c252f7c81216913eb4c223d2527e353b/STAND8_RGB_1.png"
        }
      />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={"belo"} />
      <meta property="og:url" content={`${location?.origin}${pageUrl}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;

const getRobot = (indexable) => {
  if (indexable === false || indexable === null) {
    return <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />;
  } else {
    return <meta name="ROBOTS" content="FOLLOW,INDEX" />;
  }
};

// Fragment for the Services Card content type
export const globalSEOFragment = graphql`
  fragment GlobalSEOFragment on Node {
    ... on ContentfulGlobalSeo {
      __typename
      contentful_id
      name
      pageUrl
      pageTitle
      indexibility
      socialSharingImage {
        file {
          url
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`;
