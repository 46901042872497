import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Section = (props) => {
  const { style, children } = props;

  return (
    <SectionLayout style={style}>
      <SectionContainer>{children}</SectionContainer>
    </SectionLayout>
  );
};

Section.propTypes = {
  // Component children
  children: PropTypes.node.isRequired,
  // Custom styling
  style: PropTypes.object,
};

export default Section;

const SectionLayout = styled.div.attrs({
  className: "page-section",
})`
  position: relative;
`;

const SectionContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;
