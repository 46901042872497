import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      width="75"
      height="39"
      viewBox="0 0 75 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7129 1L74.0726 19.3597L55.7129 37.7194"
        stroke="#00D4C1"
        strokeWidth="1.26619"
      />
      <path d="M74.0288 19.3597H0" stroke="#00D4C1" strokeWidth="1.45956" />
    </svg>
  );
};

export default ArrowIcon;
