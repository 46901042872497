import React, { useRef } from "react";
import styled from "styled-components";
import GlobalStyle from "../styles/global-style";
import Nav from "./nav";
import Blob from "./icons/blob";

const Layout = ({ children, location }) => {
  const pageLayoutRef = useRef();
  const isHome = location.pathname === "/";
  const isServices = location.pathname.includes("/services");
  const isWork = location.pathname.includes("/work");
  const isProject = location.pathname.includes("/projects/");
  const isAbout = location.pathname.includes("/about");
  const isContact = location.pathname.includes("/contact");
  const isServicesAbout = isAbout || isServices;

  return (
    <StyledLayout id="root" ref={pageLayoutRef}>
      <Blob
        isServicesAbout={isServicesAbout}
        isHome={isHome}
        isWork={isWork}
        isContact={isContact}
        isProject={isProject}
      />
      <GlobalStyle />
      <Container>{children}</Container>
      <Nav isHome={isHome} pageLayoutRef={pageLayoutRef} />
    </StyledLayout>
  );
};

export default Layout;

const Container = styled.div``;

const StyledLayout = styled.div`
  min-height: calc(100vh);
`;
