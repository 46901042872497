const theme = {
  colors: {
    lightGrey: "#C4C4C4",
    darkGrey: "#2C2C2C",
    black: "#000000",
    darkTurquoise: "#00D4C1",
    white: "#ffffff",
    bolbColor1: "#5048a0",
    bolbColor2: "#85ede8",
    bolbColor3: "#48098b",
  },
  fonts: {
    neueHaasGroteskDisplay: "neue-haas-grotesk-display, sans-serif",
    neueHaasGroteskText: "neue-haas-grotesk-text, sans-serif",
  },
  fontSizes: {
    h1: "100px",
  },
  transition: `all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)`,
  easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  loaderDelay: 500,
};

export default theme;
