import { createGlobalStyle } from "styled-components";
import FontFaces from "./fonts";
import theme from "./theme";
import TransitionStyles from "./transition-styles";

const { fonts } = theme;

const GlobalStyle = createGlobalStyle`
  ${FontFaces};
  html {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  } 
  body {
    font-family: ${fonts.neueHaasGroteskDisplay};
    margin: 0px !important;
    overflow-x: hidden !important;
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
  }

  ${TransitionStyles};
`;

export default GlobalStyle;
