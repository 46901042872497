import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

import AnimationLink from "../animation-link";
import Section from "../section";
import media from "../../styles/media";

const FooterMapDetails = (props) => {
  const {
    location,
    menu,
    backgroundImage,
    description,
    copyright,
    logo,
    icon,
    pageLayoutRef,
  } = props;
  const isWork = location.pathname.includes("/work");
  const isProject = location.pathname.includes("/projects/");
  const isEnable = isWork || isProject;
  return (
    <Section>
      <EmptyEle isEnable={isEnable} />
      <MenuContainer>
        <Menu>
          {menu.item.map((item, index) => {
            return item.link.type === "entry-link" ? (
              <StyledItem key={index}>
                <AnimationLink
                  navLink={`/${item.link.entry.slug}`}
                  pageLayoutRef={pageLayoutRef}
                >
                  {item.text}
                </AnimationLink>
              </StyledItem>
            ) : (
              item.link.type === "external-link" && (
                <StyledItem key={index}>
                  <a
                    href={item.link.externalLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.text}
                  </a>
                </StyledItem>
              )
            );
          })}
        </Menu>
        <IconWrapp>
          {/* <BlackCurveIcon /> */}
          <Img fluid={icon.fluid} title={icon.title} />
        </IconWrapp>
      </MenuContainer>
      <MapDetailsContainer>
        <GridContainer>
          <MapWrapp>
            <Img fluid={backgroundImage.fluid} title={backgroundImage.title} />
          </MapWrapp>
          <DescriptionWrapp>
            <Description>{description?.description}</Description>
          </DescriptionWrapp>
        </GridContainer>
      </MapDetailsContainer>
      <LogoWrapp>
        <Img fluid={logo.fluid} title={logo.title} />
        <StyledText>{copyright}</StyledText>
      </LogoWrapp>
    </Section>
  );
};

export default FooterMapDetails;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div`
  display: flex;
  div:first-child {
    padding-left: 0;
  }
  div:last-child {
    border-right: 0px;
  }
`;

const IconWrapp = styled.div`
  width: 62px;
  height: 39px;
  ${media.tablet`
    width: 33px; height: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
  `};
`;

const StyledItem = styled.div`
  padding-left: 35px;
  padding-right: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 91.6%;
  color: #000000;
  border-right: 1px solid #00d4c1;
  padding-top: 8px;
  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      outline: 0;
      &:after {
        width: 100%;
      }
      & > * {
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    &:after {
      content: "";
      display: block;
      width: 0px;
      height: 8px;
      position: relative;
      bottom: 8px;
      background-color: #fb8fa4;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      opacity: 0.5;
    }
  }
  ${media.bigDesktop`
    font-size: 1rem;
  `};
  ${media.tablet`
    font-size: .875rem;
    padding: 6px 15px 0px 15px;
  `};
  ${media.phone`
    font-size: .75rem;
    padding: 6px 8px 0px 8px;
  `};
`;

const EmptyEle = styled.div`
  width: 100%;
  height: ${({ isEnable }) => (isEnable ? "0px" : "141px")};
  ${media.tablet`height: ${({ isEnable }) => (isEnable ? "0px" : "94px")};`};
  ${media.thone`height: ${({ isEnable }) => (isEnable ? "0px" : "42px")};`};
`;

const LogoWrapp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: -180px;
  .gatsby-image-wrapper {
    width: 690px;
    ${media.bigDesktop`width: 497px;`};
    ${media.tablet`width: 180px;`};
  }

  ${media.bigDesktop`margin-top: -100px;`};
  ${media.desktop`margin-top: -52px;`};
  ${media.tablet`margin-top: 0px;`};
`;

const StyledText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 126.6%;
  color: black;
  transform: translate(-52px, -46px);
  ${media.bigDesktop`
    font-size: 1rem;
  `};
  ${media.tablet`transform: translate(-20px, -28px); font-size: 0.875rem;`};
  ${media.thone`transform: translate(-16px, -28px); font-size: .75rem;`};
`;

const MapDetailsContainer = styled.div`
  margin-top: 82px;
  width: 100%;
  ${media.bigDesktop`margin-top: 62px;`};
  ${media.desktop`margin-top: 52px;`};
  ${media.tablet`margin-top: 42px;`};
  ${media.thone`margin-top: 22px;`};
`;

const GridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.tablet`gap: 0px 15px;`};
  ${media.phablet`gap: 0px 8px;`};
`;

const MapWrapp = styled.div`
  grid-column: 2 / -2;
  grid-row: 1 / -1;
  ${media.desktop`grid-column: 1 / -1;`};
`;

const DescriptionWrapp = styled.div`
  grid-column: 8 / -1;
  grid-row: 1 / -1;
  padding-top: 52px;
  z-index: 2;
  ${media.bigDesktop`grid-column: 6 / -1;`};
  ${media.tablet`grid-column: 4 / -1;`};
  ${media.thone`grid-column: 1 / -4;`};
  ${media.phablet`grid-column: 1 / -1;`};
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  opacity: 0.6;
  ${media.giant`font-size: 1.8rem;`};
  ${media.bigDesktop`font-size: 1.5rem;`};
  ${media.desktop`font-size: 1.5rem;`};
  ${media.thone`font-size: 1.3rem;`};
`;
